import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Cloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        d="M37 288.7C37 358.638 94.2179 415.334 164.8 415.334H356.5C415.319 415.334 463 367.619 463 308.76C463 265.057 436.713 224.696 399.1 208.25C395.117 138.966 337.188 84 266.313 84C210.983 84 163.544 119.177 143.5 166.833C79.6 177.188 37 230.736 37 288.7Z"
        strokeWidth="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
