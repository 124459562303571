import React from "react";
import Layout from "../../components/Layout";
import Meta from "../../components/Meta";
import * as styles from "../../styles/case-study.module.css";
import * as heroStyles from "../../styles/hero-section.module.css";
import Dart from "../../components/icons/Dart";
import Bulb from "../../components/icons/Bulb";
import Presentation from "../../components/icons/Presentation";
import Server from "../../components/icons/Server";
import Cloud from "../../components/icons/Cloud";
import Graph from "../../components/icons/Graph";
import LinearReveal from "../../components/animation/LinearReveal";
import HeroSection from "../../components/HeroSection";
import Card from "../../components/Card";

export default function SalesforceIntegration() {
  const csr = [
    {
      icon: <Dart />,
      title: "Challenge",
      description:
        "Inadequate Bid and Proposal management, complex and manual process, and poor insights",
    },
    {
      icon: <Bulb />,
      title: "Solution",
      description:
        "A platform built on Sales Cloud that would synchronize data, provide self-service options, and create actionable insights",
    },
    {
      icon: <Presentation />,
      title: "Results",
      description:
        "Streamlined Bid management, unified data, and analytics that improve pipeline and conversion",
    },
  ];

  const solutionDesc = [
    "Synchronize prospective and current customers with right contacts, account relationships, technical information, tasks, chatter, and opportunities could be managed more effectively",
    "Deliver a robust platform l to enhance the cross-selling, reduce process time and eliminate manual work",
    "Centralize important data into dashboards to create actionable insights to improve operations in real time",
    "Sales Cloud offers a 360-degree view of the Customer, all interactions by different business divisions with the Customer,  and communication preferences.",
    "Client would be able to better manage bids and proposals from lead to opportunity closure, meaning that data would be accessible and actionable.",
  ];

  const results = [
    {
      icon: <Server />,
      title: "Streamlined Bid Management",
    },
    {
      icon: <Cloud />,
      title: "Unified Data",
    },
    {
      icon: <Graph />,
      title: "Analytics that improve Pipeline and Conversion",
    },
  ];

  return (
    <Layout>
      <Meta title="Salesforce Integration - DBT" description="Salesforce Integration" />
      <HeroSection
        title="Salesforce Integration"
        titleClass={"text-primary " + heroStyles.heroTitle}
        imageClass={heroStyles.salesforceIntegrationImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <article className={"px-4 " + styles.article}>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered px-0 " + styles.heading}>
            When the Spreadsheet Spreads Bid and Proposal Management too thin
          </h1>{" "}
        </LinearReveal>
        <LinearReveal>
          <p className={styles.description}>
            See how Digital Biz Tech created a platform that would scale with{" "}
            <strong className="text-heading"> our Aerospace Client's </strong> ambitious
            growth initiatives. <br />
            Our Aerospace client is a growing designer and manufacturer of advanced
            materials-based engineered components for demanding aerospace applications,
            supporting both commercial and military platforms.
          </p>
        </LinearReveal>
        <LinearReveal>
          <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto mt-4">
            {csr.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ width: "280px", paddingTop: "30px" }}
                  contentClass="text-description has-text-centered "
                  cardContentClass="px-4 pb-2"
                  content={card.description}
                />
              </div>
            ))}
          </div>
        </LinearReveal>
        <LinearReveal>
          <p className={"mt-4 " + styles.description}>
            Prior to Salesforce Sales Cloud, Client managed Opportunities (Bids and
            Proposals - legacy terminology) in spreadsheets. Due to rapid growth, Client
            realized it would need an automated platform - not spreadsheets - in order to
            manage and improve pipeline & conversion
          </p>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 has-text-centered text-primary " + styles.title}>
            The Challenge
          </h2>
          <h3 className={"has-text-centered text-heading mt-2 " + styles.title}>
            DISPARATE DATA In spreadsheets and SAP ERP Systems
          </h3>
          <p className={styles.description}>
            Client uses SAP ERP system and it doesn't meet their opportunity management
            needs. Client used spreadsheets to track their contacts and opportunities. Due
            to rapid growth, Client realized the limitations of using spreadsheets to
            track and collaborate.
          </p>
          <p className={styles.description}>
            Furthermore, growing business / cross selling to their existing customer base
            was not obvious as the data was siloed between different business segments.
          </p>
          <p className={styles.description}>
            The team needed a centralized system to house contacts, Accounts and
            opportunities, and provide actionable insights. It became clear that Client
            needed to re-imagine its processes in order to continue to grow.
          </p>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 has-text-centered text-primary " + styles.title}>
            The Solution
          </h2>
          <p className={styles.description}>
            Salesforce Sales Cloud, Power BI reporting, Outlook Integration and Einstein
            Analytics. Digital Biz Tech and Client worked together to envision a
            centralized platform that would:
          </p>
          <ul>
            {solutionDesc.map((desc, i) => (
              <li key={i} className={styles.soultions}>
                {desc}
              </li>
            ))}
          </ul>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 has-text-centered text-primary " + styles.title}>
            The Results
          </h2>
          <p className={styles.description}>
            As a result the Client saw a number of immediate improvements.
          </p>

          <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto mt-4">
            {results.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ width: "280px", paddingTop: "30px" }}
                  cardContentClass="px-2 pb-2"
                />
              </div>
            ))}
          </div>
        </LinearReveal>
      </article>
    </Layout>
  );
}
