import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Graph({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: { fill: "var(--primary)" },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="15"
        d="M0 250V500H250H500V477.749V455.497H272.251H44.5026V227.749V0H22.2513H0V250ZM197.967 205.825L198.298 411.649L239.202 411.995L280.105 412.34V206.17V0H238.87H197.636L197.967 205.825ZM87.6963 283.377V412.304H128.272H168.848V283.377V154.45H128.272H87.6963V283.377ZM308.901 283.377V412.304H349.476H390.052V283.377V154.45H349.476H308.901V283.377ZM419.721 279.669C419.241 280.148 418.848 310.187 418.848 346.423V412.304H459.424H500V345.55V278.796H460.297C438.459 278.796 420.2 279.188 419.721 279.669Z"
      />
    </motion.svg>
  );
}
